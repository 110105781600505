import React from 'react'; 
import SiteLayout from '@layouts/site-layout';

export default () => (
    <SiteLayout>
        <section className="section top_margin_comn">
            <div className="container">
                <div className="content thanks_sec">
                    <h1>Thank you!</h1>
                    {/* <p> We have received your contact request. STEPapp team will reach out to you soon.</p> */}
                    <p> Our STEPapp team will respond to you in 24hrs.</p>
                </div>
            </div>
        </section>
    </SiteLayout>
)
